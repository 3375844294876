import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import MainLayout from '../layouts/main';
import MainLayoutWithOffset from '../layouts/mainWithOffset';
import AgentesLayout from '../layouts/agentes';

import LoadingScreen from '../components/LoadingScreen';
import LoginLayout from '../layouts/login';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <Home /> },
        { path: 'nosotros', element: <About /> },
        { path: 'refiere-y-gana', element: <RefiereYGana /> },
        // { path: 'aviso-de-privacidad', element: <AvisoDePrivacidad /> },
        { path: 'aviso-de-privacidad', element: <Navigate to="/404" replace /> },
        // { path: 'terminos-y-condiciones', element: <TerminosYCondiciones /> },
        { path: 'terminos-y-condiciones', element: <Navigate to="/404" replace /> },
        {
          path: 'politica-de-cookies',
          element: <PoliticasDeCookies />
        },
        {
          path: 'autorizacion-de-uso-de-medios-electronicos',
          element: <AutorizacionDeUsoDeMediosElectronicos />
        }
      ]
    },
    {
      path: '/',
      element: <MainLayoutWithOffset />,
      children: [{ path: 'simulador', element: <Simulador /> }]
    },
    {
      path: '/productos',
      element: <MainLayout />,
      children: [
        { path: 'credito-solucion', element: <CreditoSolucion /> },
        { path: 'credito-comercial', element: <CreditoComercial /> },
        { path: 'credito-construccion', element: <CreditoConstruccion /> },
        { path: 'mejora-tu-cuota', element: <MejoraTuCuota /> }
      ]
    },
    {
      path: '/agentes',
      element: <AgentesLayout />,
      children: [{ path: '', element: <Agentes /> }]
    },
    {
      path: '/agentes/register',
      element: <LoginLayout />,
      children: [
        { path: '', element: <Register /> },
        { path: 'thank-you', element: <ThankYou /> }
      ]
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IBAN

const Home = Loadable(lazy(() => import('../pages/Iban/home/Home')));
const About = Loadable(lazy(() => import('../pages/Iban/about/About')));
const CreditoSolucion = Loadable(
  lazy(() => import('../pages/Iban/credito-solucion/CreditoSolucion'))
);
const CreditoComercial = Loadable(
  lazy(() => import('../pages/Iban/credito-comercial/CreditoComercial'))
);
const CreditoConstruccion = Loadable(
  lazy(() => import('../pages/Iban/credito-construccion/CreditoConstruccion'))
);
const MejoraTuCuota = Loadable(lazy(() => import('../pages/Iban/mejora-tu-cuota/MejoraTuCuota')));
const RefiereYGana = Loadable(lazy(() => import('../pages/Iban/refiere-y-gana/RefiereYGana')));
const AvisoDePrivacidad = Loadable(
  lazy(() => import('../pages/Iban/aviso-de-privacidad/AvisoDePrivacidad'))
);
// const PoliticasDePrivacidad = Loadable(
//   lazy(() => import('../pages/Iban/politicas-de-privacidad/PoliticasDePrivacidad'))
// );
const TerminosYCondiciones = Loadable(
  lazy(() => import('../pages/Iban/terminos-y-condiciones/TerminosYCondiciones'))
);
const PoliticasDeCookies = Loadable(
  lazy(() => import('../pages/Iban/politicas-de-cookies/PoliticasDeCookies'))
);

const AutorizacionDeUsoDeMediosElectronicos = Loadable(
  lazy(
    () =>
      import(
        '../pages/Iban/autorizacion-de-uso-de-medios-electronicos/AutorizacionDeUsoDeMediosElectronicos'
      )
  )
);

const Agentes = Loadable(lazy(() => import('../pages/Iban/agentes/Agentes')));

const Register = Loadable(lazy(() => import('../pages/Iban/login/Register')));

const Simulador = Loadable(lazy(() => import('../pages/Iban/simulador/Simulador')));

const ThankYou = Loadable(lazy(() => import('../pages/Iban/thank-you/ThankYou')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
